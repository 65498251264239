import React from 'react';
import './App.css';
import { ReactComponent as VelocityLogo } from './Velocity.svg';

function App() {
  // Set CSS variable for viewport height
  React.useEffect(() => {
    const setHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set initial height
    setHeight();

    // Update height on resize
    window.addEventListener('resize', setHeight);

    // Cleanup
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  return (
    <div className="App">
      <VelocityLogo className="logo" />
    </div>
  );
}

export default App;
